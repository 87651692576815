import { doc, getDoc, setDoc } from 'firebase/firestore';
import { BehaviorSubject } from 'rxjs';
import { firestore } from '../Helpers/firebase/firebase';
import { Permissions, permissionsSchema } from './permission.schema';
import { User } from './user.schema';

export const permissionStore$ = new BehaviorSubject<Permissions | undefined>(undefined);

export function permissionMigrationCheck(rawData: any): Permissions {
	const empty = permissionsSchema.parse({});

	// force removal of promo tab etc
	const new_data = { ...empty, ...rawData } as Permissions;
	new_data.can_use_promo_tab = false;
	new_data.can_use_simple_message_tab = true;
	new_data.can_send_campaigns = false;

	return new_data; // { ...empty, ...rawData } as Permissions;
	//  return permissionsSchema.parse(rawData);
}

export async function loadPermissionsForUser(user: User) {
	const docPermissionsRef = doc(firestore, `/permissions/${user.uid}`);

	const docSnap = await getDoc(docPermissionsRef);
	if (docSnap.exists()) {
		permissionStore$.next(permissionMigrationCheck(docSnap.data()));
	} else {
		setDoc(docPermissionsRef, user.permissions);
		permissionStore$.next(permissionMigrationCheck(user.permissions));
	}
}

export function logOutPermissionStore() {
	permissionStore$.next(undefined);
}
