import { BehaviorSubject } from 'rxjs';
import { config } from '../../../assets/config';
import FAQitems from '../../../assets/faq-example.json';
import { provideListDataStore } from '../../Helpers/load-listener';
import { FAQ } from './faq.schema';

export const FAQSampleDatabase = FAQitems as FAQ[];

function getDocPath(tenant_id: string | undefined) {
	if (tenant_id && tenant_id.length > 0) return `tenants/${tenant_id}/faq`;
	if (config.tenants.length == 1) return `tenants/${config.tenants[0].id}/faq`;
	return `faq`;
}

const dataType = 'faq';
const dataDocName = 'faq_list';

export let saveNewFaqList = async (newFaq: FAQ[]): Promise<void> => {
	console.warn('Should no be called, placeholder function', newFaq);
};
export let logOutFAQStore = () => {};

export let FAQStore$ = new BehaviorSubject<FAQ[]>([]);

export function loadFAQAndSubscribe(tenant_id: string | undefined) {
	const dataStore = provideListDataStore<FAQ>(dataType, dataDocName, FAQSampleDatabase, getDocPath(tenant_id));

	saveNewFaqList = dataStore.newListFn;
	FAQStore$ = dataStore.store$;
	logOutFAQStore = dataStore.logOutFn;
}

export function upsertFAQ(newItem: FAQ) {
	let newList = [...FAQStore$.getValue()];
	if (!newList) newList = [];

	newList = newList.filter(item => item.id != newItem.id);
	newList = [...newList, newItem];

	newList = newList
		.sort((a, b) => a.order - b.order)
		.map((item, i) => {
			return { ...item, order: i };
		});

	return saveNewFaqList(newList);
}
