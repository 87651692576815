import { doc, getDoc } from 'firebase/firestore';
import { useEffect } from 'react';
import { useHistory } from 'react-router';
import { firestore } from '../Helpers/firebase/firebase';

import { LogAnalytics } from '../Stats/ga';
import { Shortcode, validURLs } from './shortcode.schema';

export function ShortcodeRedirector() {
	//
	// hooks
	//
	const history = useHistory();

	// get any query parameter to fill the invitecode
	const queryParams = new URLSearchParams(window.location.search);
	const receivedCode = queryParams.get('shortcode');
	const receivedURL = queryParams.get('url');

	useEffect(() => {
		LogAnalytics('Shortcode', 'start');
		if (receivedCode && receivedCode.length == 6) {
			LogAnalytics('NewUser', 'received_code');
		}

		doShortcodeAction(receivedCode, receivedURL);
	}, []);

	//
	// variables and setters
	//

	//
	// methods
	//
	async function doShortcodeAction(receivedCode: string | null, receivedURL: string | null) {
		let isValid = false;
		if (receivedCode && receivedURL && receivedCode.length === 6 && validURLs.includes(receivedURL)) isValid = true;

		if (!isValid) {
			LogAnalytics('Shortcode', 'not_valid_shortcode');

			if (receivedURL && validURLs.includes(receivedURL)) history.replace(receivedURL);
			else history.replace('/tabs');
		}

		if (isValid && receivedCode) {
			const docRef = doc(firestore, 'shortcodes', receivedCode);
			const docSnap = await getDoc(docRef);

			if (docSnap.exists()) {
				LogAnalytics('Shortcode', 'found_shortcode');

				const redirectData = docSnap.data() as Shortcode;
				history.replace(String(redirectData));
			} else {
				LogAnalytics('Shortcode', 'not_found_shortcode');

				history.replace(String(receivedURL));
			}
		}
	}

	return <></>;
}
