import { config } from '../../assets/config';
import { auth, getHttpOptionsForREST } from '../Helpers/firebase/firebase';
import { dev } from '../Helpers/utils';

export async function checkInviteCode(inviteCode: string): Promise<boolean> {
	let inviteCheckResult: { result: boolean; error: number } | undefined = undefined;

	const uid = auth.currentUser?.uid;

	const url = (dev ? config.apiDev : config.apiProd) + `inviteapi/${uid}/${inviteCode}`;

	const httpOptions = await getHttpOptionsForREST();

	try {
		inviteCheckResult = await fetch(url, {
			method: 'GET',
			headers: httpOptions.headers,
		}).then(res => res.json());

		return !!inviteCheckResult?.result;
	} catch (e) {
		console.log('inviteCheckResult error fetch', e);
		return false;
	}
}
