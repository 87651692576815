import { BehaviorSubject } from 'rxjs';
import { provideListDataStore } from '../../Helpers/load-listener';

import PromotionalMessageSampleDB from '../../../assets/promotionmessage-example.json';
import { PromoType, PromotionalMessage } from './promotionalmessage.schema';

// if we are in multi-tenant and there is a multi-tenant defined, we give that path
function getDocPath(tenant_id: string | undefined) {
	// if there is a user-defined tenant, we use that one
	if (tenant_id && tenant_id.length > 0) return `tenants/${tenant_id}/promotionalmessage`;

	return `promotionalmessage`;
}

const dataType = 'promotionalmessage';
const dataDocName = 'promotionalmessage_list';

export const promotionalMessageSampleDatabase = PromotionalMessageSampleDB as PromotionalMessage[];

export let saveNewPromotionalMessageList = async (newItems: PromotionalMessage[]): Promise<void> => {
	console.warn('Should not be called', newItems);
};
export let logOutPromotionalMessageStore = () => {};
export let promotionalMessageStore$ = new BehaviorSubject<PromotionalMessage[]>(
	//@ts-ignore need a type converter
	PromotionalMessageSampleDB
);

export function loadPromotionalMessageAndSubscribe(tenant_id: string | undefined) {
	const dataStore = provideListDataStore<PromotionalMessage>(
		dataType,
		dataDocName,
		promotionalMessageSampleDatabase,
		getDocPath(tenant_id),

		data => {
			const newData = { ...data };
			if (data.type === undefined) newData.type = PromoType.url;
			return newData as PromotionalMessage;
		}
	);

	saveNewPromotionalMessageList = dataStore.newListFn;
	promotionalMessageStore$ = dataStore.store$;
	logOutPromotionalMessageStore = dataStore.logOutFn;
}

export function upsertPromotionalMessage(newItem: PromotionalMessage) {
	let newList = [...promotionalMessageStore$.getValue()];
	if (!newList) newList = [];

	newList = newList.filter(item => item.id != newItem.id);
	newList = [...newList, newItem];

	newList = newList
		.sort((a, b) => a.order - b.order)
		.map((item, i) => {
			return { ...item, order: i };
		});

	return saveNewPromotionalMessageList(newList);
}
