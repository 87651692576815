import { IonApp, IonRouterOutlet, setupIonicReact } from '@ionic/react';
import './App.css';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/display.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/padding.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';

/* Theme variables */
import './theme/variables.css';

import { IonReactRouter } from '@ionic/react-router';

import { Redirect, Route } from 'react-router';

import { Login } from './lib/Login/Login';

import React, { lazy, Suspense, useContext, useEffect } from 'react';
import { Loading } from './lib/Login/Loading';
import { Splash } from './lib/Splash/Splash';
import { NewUser } from './lib/User/NewUser';

import { deprecatedFbMessagingServiceWorker, unregisterServiceWorker } from './lib/Helpers/sw-helper';
import { startTokenRefresher, tokenRefresherIsActive } from './lib/Login/token-refresher';
import { ShortcodeRedirector } from './lib/Shortcodes/Shortcode';
import { ProtectedRoute } from './Protected';
import SimpleMessagesContextProvider from './lib/stores/simple-messages-context/simple-messages-context';
import i18n from 'i18next';
import { config } from './assets/config';
import localforage from 'localforage';
import { LocalSaveStates } from './lib/Helpers/localstorage';
import { LocaleInterface } from './lib/stores/locale-context/locale.schema';
import { LocaleContext } from './lib/stores/locale-context/locale-context';

// Force the same ionic style on every device
setupIonicReact({
	mode: 'md',
});

const TabsPromise = import('./lib/Tabs/Tabs');
const Tabs = lazy(() => TabsPromise);

console.log('App version', APP_VERSION);

function App() {
	const { setLocales } = useContext(LocaleContext);

	useEffect(() => {
		// Check if token refresher is active
		if (!tokenRefresherIsActive()) {
			// Start refresher.
			startTokenRefresher();
		}

		// Get locales from local storage
		localforage.getItem<LocaleInterface[] | undefined>(LocalSaveStates.LOCALES).then(localesFromLocalStorage => {
			if (localesFromLocalStorage) {
				// Set in context store
				setLocales(localesFromLocalStorage);
				// Find last loggedin user
				localforage.getItem(LocalSaveStates.LAST_LOGIN).then(userID => {
					// Get last login user locale
					let lastLocale: string = localesFromLocalStorage.find(locale => locale.userId === userID)?.locale || '';

					// Get last set locale if last login user locale is not found
					if (!lastLocale) {
						lastLocale = localesFromLocalStorage[localesFromLocalStorage.length - 1]?.locale || '';
					}

					// Set user preferred locale
					if (lastLocale) {
						// Activated the locale
						i18n.changeLanguage(`${config.localeGroup}_${lastLocale}`);
					}
				});
			}
		});
	}, []);

	// Unregister deprecated tabs service worker if active
	unregisterServiceWorker(deprecatedFbMessagingServiceWorker).catch(error => console.log(error));
	// Register service worker for Firebase Cloud Messaging
	//	registerServiceWorker(fbMessagingServiceWorker).catch(error => console.log(error));

	return (
		<IonApp>
			<IonReactRouter>
				<IonRouterOutlet placeholder="">
					<Route exact path="/splash" render={() => <Splash />} />

					<Route exact path="/login" render={() => <Login />} />

					<Route exact path="/shortcode" render={() => <ShortcodeRedirector />} />

					<Route
						exact
						path="/newuser"
						render={() => (
							<ProtectedRoute>
								<NewUser />
							</ProtectedRoute>
						)}
					/>

					<Route
						path="/tabs"
						render={() => (
							<ProtectedRoute>
								<Suspense fallback={<Loading />}>
									<SimpleMessagesContextProvider>
										<Tabs />
									</SimpleMessagesContextProvider>
								</Suspense>
							</ProtectedRoute>
						)}
					/>

					<Route exact path="/" render={() => <Redirect to={'/splash'} />} />

					<Route render={() => <Redirect to={'/splash'} />} />
				</IonRouterOutlet>
			</IonReactRouter>
		</IonApp>
	);
}

export default App;
