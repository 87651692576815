import { AlertOptions, alertController, toastController } from '@ionic/core';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { Observable } from 'rxjs';
import { writeToClipboard } from '../Admin/InviteCodes/invite-helper';

export const dev = import.meta.env.MODE === 'development' || import.meta.env.DEV;

export function formatMobileNumber(mobileNumber: string, prefix: string): string {
	// if the number has '+' we assume the user has done a proper job
	if (mobileNumber.charAt(0) === '+') return '+' + mobileNumber.replace(/\D/g, '');

	const countryCode = prefix.replace('+', '');

	// remove all non-numeric characters
	let numericMobileNumber = mobileNumber.replace(/\D/g, '');

	// override for Dutch numbers (testing)
	if (mobileNumber.startsWith('+31')) return `+${numericMobileNumber}`;

	if (numericMobileNumber.startsWith('00')) numericMobileNumber = numericMobileNumber.substring(2);

	if (numericMobileNumber.startsWith('0')) numericMobileNumber = countryCode + numericMobileNumber.substring(1);

	if (!numericMobileNumber.startsWith(countryCode)) numericMobileNumber = countryCode + numericMobileNumber;

	// check if the first character is a + sign
	if (numericMobileNumber.charAt(0) !== '+') {
		// add a + sign to the beginning of the mobile number
		numericMobileNumber = `+${numericMobileNumber}`;
	}

	// return the mobile number as is, since it already has a + sign
	return numericMobileNumber;
}

export function removeNonNumeric(str: string) {
	return str.replace(/[^0-9,.]/g, '');
}

export function removeDiacritics(str: string) {
	return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}

// https://github.com/hungry-egg/rx-react

export function get<T>(observable$: Observable<T>) {
	let value;
	observable$.subscribe(val => (value = val)).unsubscribe();
	return value as unknown as T;
}

export function useRXJS<T>(observable$: Observable<T>) {
	const [value, setValue] = useState(() => get(observable$));

	useEffect(() => {
		const subscription = observable$.subscribe(setValue);
		return function cleanup() {
			subscription.unsubscribe();
		};
	}, [observable$]);

	return value as T;
}

export function convertToNumberOrDefault(input: string, def: number) {
	const number = Number(input);
	return isNaN(number) ? def : number;
}

export function formatLargeNumber(number: number): string {
	const numberString = number.toString();

	if (numberString.length <= 3) {
		return numberString; // No need for dot separation if the number has less than three digits
	}

	const lastThreeDigits = numberString.slice(-3);
	const remainingDigits = numberString.slice(0, -3);

	return `${remainingDigits}.${lastThreeDigits}`;
}

export function removeTextAndTrimNewlines(input: string): string {
	if (input == undefined) return 'nothing';
	const index = input.indexOf('\n');
	if (index !== -1) {
		input = input.substring(index + 1);
	}
	return input.trimStart();
}

export function getRandomItemFromArray(array: any) {
	if (array.length === 0) {
		return undefined;
	}

	const randomIndex = Math.floor(Math.random() * array.length);
	return array[randomIndex];
}

export function containsFourNumbersOnly(inputString: string): boolean {
	// Define a regular expression pattern to match 5 numbers in a row
	const pattern = /\d{4}/;

	// Use the test() method to check if the inputString contains the pattern
	return pattern.test(inputString) && inputString.length == 4;
}

export function capitalizeFirstLetter(inputString: string): string {
	if (inputString.length === 0) {
		return inputString; // Return the original string if it's empty
	}

	const firstLetter = inputString[0].toUpperCase();
	const restOfString = inputString.slice(1).toLowerCase();

	return firstLetter + restOfString;
}

// a function that gets a string as input and capitalizes the first letter of each word
export function capitalizeEachWord(inputString: string): string {
	// Split the input string into words
	const words = inputString.split(' ');

	// Capitalize the first letter of each word
	const capitalizedWords = words.map(word => capitalizeFirstLetter(word));

	// Join the capitalized words back together
	const result = capitalizedWords.join(' ');

	return result;
}

export function increaseObjectCountMapCatKey(map: { [key: string]: any }, cat: string, key: string, count: number) {
	const newMap = { ...map };
	if (!newMap[cat]) {
		newMap[cat] = {};
	}
	if (!newMap[cat][key]) newMap[cat][key] = 0;

	newMap[cat][key] = newMap[cat][key] + count;

	return newMap;
}

export function increaseObjectCountMapKey(
	map: { [key: string]: any },

	key: string,
	count: number
) {
	const newMap = { ...map };

	if (!newMap[key]) newMap[key] = 0;

	newMap[key] = newMap[key] + count;

	return newMap;
}

export function deepEqual(obj1: any, obj2: any): boolean {
	// Check if both objects are the same type
	if (typeof obj1 !== typeof obj2) {
		return false;
	}

	// If they're primitive types, compare them directly
	if (typeof obj1 !== 'object' || obj1 === null) {
		return obj1 === obj2;
	}

	// Check if they have the same keys
	const keys1 = Object.keys(obj1);
	const keys2 = Object.keys(obj2);

	if (keys1.length !== keys2.length) {
		return false;
	}

	// Recursively compare each property
	for (const key of keys1) {
		if (!deepEqual(obj1[key], obj2[key])) {
			return false;
		}
	}

	// If all checks pass, the objects are deeply equal
	return true;
}

export function splitName(fullName: string): { first_name: string; last_name: string } {
	const parts = fullName.split(' ');
	const first_name = parts[0] || '';
	const last_name = parts.slice(1).join(' ');
	return { first_name, last_name };
}

/*
npm install lodash.isequal
npm install --save-dev @types/lodash.isequal

import isEqual from 'lodash.isequal';

console.log(isEqual(obj1, obj2)); // true
*/

export function formatEpochToDateTime(epoch: number): string {
	const date = new Date(epoch);
	const options: Intl.DateTimeFormatOptions = {
		dateStyle: 'long',
		timeStyle: 'short',
	};
	return date.toLocaleString(undefined, options);
}

// simple collusion full hashing
export function stringToAsciiSum(inputString: string): number {
	let sum = 0;

	for (let i = 0; i < inputString.length; i++) {
		const char = inputString.charCodeAt(i);
		sum += char;
	}

	return sum;
}

export function isURL(str: string): boolean {
	// Regular expression pattern to match URLs
	const urlPattern = /^(https?:\/\/)?(www\.)?[a-zA-Z0-9_-]+\.[a-zA-Z0-9]+\S*$/i;

	// Test if the string matches the URL pattern
	return urlPattern.test(str);
}

export async function presentToast(position: 'top' | 'middle' | 'bottom', message: string, duration?: number) {
	const toast = await toastController.create({
		message,
		duration: duration === undefined ? 5500 : duration,
		position: position,
		buttons: [
			{
				text: t('general.close') as string,
				role: 'cancel',
				handler: () => {},
			},
		],
	});
	return toast.present();
}

export function copyAndToast(message: string) {
	writeToClipboard(message);
	presentToast('bottom', `Copied ${message} to clipboard`);
}

export async function presentOkAlert(title: string, message: string) {
	const alertOptions = {
		header: title,
		message,
		buttons: [t('general.close') as string],
	};

	alertController.create(alertOptions as AlertOptions).then(alert => alert.present());
}

export function removeLeadingAndTrailingLines(input: string): string {
	// Split the input string into lines
	const lines = input.split('\n');

	// Remove leading empty lines
	while (lines.length > 0 && lines[0].trim() === '') {
		lines.shift();
	}

	// Remove trailing empty lines
	while (lines.length > 0 && lines[lines.length - 1].trim() === '') {
		lines.pop();
	}

	// Remove the first and last lines
	if (lines.length >= 2) {
		lines.shift(); // Remove the first line
		lines.pop(); // Remove the last line
	} else {
		// If there are fewer than 2 lines left, return an empty string
		return '';
	}

	// Join the remaining lines back together
	const result = lines.join('\n');

	return result;
}
