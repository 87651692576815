export interface IsSwRegistrationRequest {
	filePath: string;
	scope: string;
}

export const fbMessagingServiceWorker: IsSwRegistrationRequest = {
	filePath: '/firebase-messaging-sw.js',
	scope: '/firebase-cloud-messaging-push-scope',
};

export const deprecatedFbMessagingServiceWorker: IsSwRegistrationRequest = {
	filePath: '/firebase-messaging-sw.js',
	scope: '/tabs',
};

/**
 * Registers the request if supported and unregistered.
 * @param swRegistrationRequest Service worker registration request.
 */
export function registerServiceWorker(swRegistrationRequest: IsSwRegistrationRequest): Promise<void> {
	return new Promise<void>((resolve, reject) => {
		if ('serviceWorker' in navigator) {
			// Get all service workers
			navigator.serviceWorker.getRegistrations().then((registrations: readonly ServiceWorkerRegistration[]) => {
				// Check if messaging service worker is not active
				if (!existsInRegistrationList(registrations)) {
					// Register service worker
					navigator.serviceWorker
						.register(swRegistrationRequest.filePath, { scope: swRegistrationRequest.scope })
						.then(registration => {
							registration.update(); // force update
							resolve();
						})
						.catch(error => {
							reject(error);
						});
				} else {
					reject('Service worker already registered.');
				}
			});
		} else {
			reject('Service workers not supported.');
		}
	});
}

/**
 * Unregisters the request if supported and registered.
 * @param swUnRegistrationRequest Service worker registration request for unregistering.
 */
export function unregisterServiceWorker(swUnRegistrationRequest: IsSwRegistrationRequest): Promise<void> {
	return new Promise<void>((resolve, reject) => {
		if ('serviceWorker' in navigator) {
			// Get all service workers
			navigator.serviceWorker.getRegistrations().then((registrations: readonly ServiceWorkerRegistration[]) => {
				// Loop through all service workers
				registrations.forEach(registration => {
					// Check if service worker is active
					if (registration.scope.includes(swUnRegistrationRequest.scope)) {
						// Unregister the service worker
						registration
							.unregister()
							.then(success => {
								if (success) {
									console.log('Service worker unregistered successfully');
								} else {
									console.error('Failed to unregister service worker');
								}
							})
							.catch(error => {
								console.error('Error during service worker unregister:', error);
							});
						return;
					}
				});
			});
		} else {
			reject('Service workers not supported.');
		}
	});
}

/**
 * Returns true if service worker exists in given array
 * @param serviceWorkerRegistrations Array with service worker registrations
 */
function existsInRegistrationList(serviceWorkerRegistrations: readonly ServiceWorkerRegistration[]): boolean {
	return serviceWorkerRegistrations.some(registration => {
		return registration.active && registration.active.scriptURL.includes('/firebase-messaging-sw.js');
	});
}
