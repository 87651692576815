export enum SimpleMessagesActionsEnum {
	SET_TEMPLATES = 'SET_TEMPLATES',
	SET_SELECTED_TEMPLATE = 'SET_SELECTED_TEMPLATE',
	SET_FARMERS = 'SET_FARMERS',
	SET_SELECTED_FARMERS = 'SET_SELECTED_FARMERS',
	SET_SENDER = 'SER_SENDER',
	SET_SEND_TO_SELF = 'SET_SEND_TO_SELF',
	SET_CUSTOM_MESSAGE = 'SET_CUSTOM_MESSAGE',
	SET_TEMPLATE_EDITED = 'SET_TEMPLATE_EDITED',
	CLEAR_STATE = 'CLEAR_STATE',
}
