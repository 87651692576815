import { Context, createContext, useReducer } from 'react';
import { LocaleContextInterface } from './locale-context.schema';
import { LocaleActionsEnum } from './locale-actions.enum';
import { LocaleReducerActionInterface } from './locale-reducer-action.schema';
import { useTranslation } from 'react-i18next';
import { config } from '../../../assets/config';
import localforage from 'localforage';
import { LocalSaveStates } from '../../Helpers/localstorage';
import { useRXJS } from '../../Helpers/utils';
import { updateAddUser, userStore$ } from '../../User/user.store';
import { LocaleInterface } from './locale.schema';

const defaultState: LocaleContextInterface = {
	locales: undefined,
	setLocales: () => {},
};

function localeReducer(state: LocaleContextInterface, action: LocaleReducerActionInterface) {
	if (action.type === LocaleActionsEnum.SET_LOCALES) {
		const locales = action.payload;

		return {
			...state,
			locales,
		};
	}

	return state;
}

export const LocaleContext: Context<LocaleContextInterface> = createContext<LocaleContextInterface>(defaultState);

export default function LocaleContextProvider({ children }: any) {
	const [localeState, localeDispatch] = useReducer(localeReducer, defaultState);
	const currentUser = useRXJS(userStore$);
	const { i18n } = useTranslation();

	function handleSetLocales(locales: LocaleInterface[]): void {
		// Set locales in the store
		localeDispatch({
			type: LocaleActionsEnum.SET_LOCALES,
			payload: locales,
		});

		// Save the locales in local forage
		localforage.setItem(LocalSaveStates.LOCALES, locales);

		// Save locale in firebase
		if (currentUser) {
			const currentUserLocale = locales.find(locale => locale.userId === currentUser.uid)?.locale;
			if (currentUserLocale) {
				currentUser.locale = currentUserLocale;
				updateAddUser(currentUser);

				// Activated the locale
				i18n.changeLanguage(`${config.localeGroup}_${currentUserLocale}`);
			}
		}
	}

	const contextValue: LocaleContextInterface = {
		locales: localeState.locales,
		setLocales: handleSetLocales,
	};

	return <LocaleContext.Provider value={contextValue}>{children}</LocaleContext.Provider>;
}
