import { IonContent, IonPage } from '@ionic/react';
import { t } from 'i18next';
import { useEffect } from 'react';
import { useHistory } from 'react-router';

import { dev } from '../Helpers/utils';
import { LogAnalytics } from '../Stats/ga';
import './Splash.css';

export const Splash = () => {
	const history = useHistory();

	LogAnalytics('Splash', 'start');

	useEffect(() => {
		const path = '/login';

		setTimeout(
			async () => {
				history.replace(path);
			},
			dev ? 3000 : 3000
		);
	}, []);

	return (
		<IonPage>
			<IonContent className="splash-page">
				<br />
				<br />
				<br />
				<br />
				<br />
				<br />
				<div className="splash-logo_center">
					<span>{t('app.name')}</span>
				</div>
				<br />
				<br />
				<div className="splash-tagline">{t('splash.tagline')}</div>
			</IonContent>
		</IonPage>
	);
};
