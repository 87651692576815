import { IonCard, IonCardContent, IonContent } from '@ionic/react';

export function Loading() {
	return (
		<IonContent>
			<IonCard>
				<IonCardContent>
					<h1>Loading....</h1>
				</IonCardContent>
			</IonCard>
		</IonContent>
	);
}
