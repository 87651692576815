import { BehaviorSubject } from 'rxjs';
import { provideListDataStore } from '../../Helpers/load-listener';

import ExploreMoreSampleDB from '../../../assets/explore-more-example.json';
import { ExploreMore } from './exploremore.schema';

// if we are in multi-tenant and there is a multi-tenant defined, we give that path
function getDocPath(tenant_id: string | undefined) {
	if (tenant_id && tenant_id.length > 0) return `tenants/${tenant_id}/exploremore`;
	return `exploremore`;
}

const dataType = 'exploremore';
const dataDocName = 'exploremore_list';

export const exploreMoreSampleDatabase = ExploreMoreSampleDB as ExploreMore[];

export let saveNewExploreMoreList = async (newExploreMores: ExploreMore[]): Promise<void> => {};
export let logOutExploreMoreStore = () => {};
export let exploreMoreStore$ = new BehaviorSubject<ExploreMore[]>(ExploreMoreSampleDB);

export function loadExploreMoreAndSubscribe(tenant_id: string | undefined) {
	const dataStore = provideListDataStore<ExploreMore>(dataType, dataDocName, exploreMoreSampleDatabase, getDocPath(tenant_id));

	saveNewExploreMoreList = dataStore.newListFn;
	exploreMoreStore$ = dataStore.store$;
	logOutExploreMoreStore = dataStore.logOutFn;
}

export function upsertExploreMore(newItem: ExploreMore) {
	let newList = [...exploreMoreStore$.getValue()];
	if (!newList) newList = [];

	newList = newList.filter(item => item.id != newItem.id);
	newList = [...newList, newItem];

	newList = newList
		.sort((a, b) => a.order - b.order)
		.map((item, i) => {
			return { ...item, order: i };
		});

	return saveNewExploreMoreList(newList);
}

// let authUnsubscribe: Unsubscribe | undefined = undefined;
// export function loadExploreMo2reAndSubscribe(tenant_id: string | undefined) {
//   authUnsubscribe = createListDataLoaderAndSubscriber<ExploreMore>(
//     exploreMoreStore$,
//     dataType,
//     dataDocName,
//     exploreMoreSampleDatabase,
//     getDocPath(tenant_id)
//   )();
// }

// export async function saveNewExplor3eMoreList(
//   tenant_id: string | undefined,
//   newExploreMores: ExploreMore[]
// ) {
//   const docRef = doc(firestore, getDocPath(tenant_id), dataDocName);

//   return setDoc(docRef, { [dataDocName]: newExploreMore });
// }

// export function logOutExplor3eMoreStore() {
//   if (authUnsubscribe) authUnsubscribe();
//   exploreMoreStore$.next([]);
// }
