import { getAnalytics, type Analytics } from 'firebase/analytics';
import { initializeApp, type FirebaseApp } from 'firebase/app';
import { AppCheck, ReCaptchaV3Provider, getToken as getAppCheckToken, initializeAppCheck } from 'firebase/app-check';
import type { Auth } from 'firebase/auth';
import { browserLocalPersistence, connectAuthEmulator, getAuth, setPersistence, type User } from 'firebase/auth';
import { Firestore, connectFirestoreEmulator, getFirestore, initializeFirestore, writeBatch } from 'firebase/firestore';
import { FirebaseStorage, connectStorageEmulator, getStorage } from 'firebase/storage';
import { BehaviorSubject } from 'rxjs';
import { config } from '../../../assets/config';
import { FirebaseConfig } from '../../../assets/config-client.schema';
import { dev } from '../utils';
import { WriteBatch } from '@firebase/firestore';
import { WriteBatchData } from './write-batch-data.schema';

export type { ConfirmationResult } from 'firebase/auth';
const MAX_BATCH_SIZE: number = 500;

export let app: FirebaseApp;
export let analytics: Analytics;
// export let messaging: Messaging | undefined;
export let firestore: Firestore;
export let auth: Auth;
export let storage: FirebaseStorage;
export let getFCMToken = () => Promise.resolve('');
export let appCheck: AppCheck;

/**
 * Initializes Firebase with the provided configuration and options -and- sets up the messaging service.
 *
 * @param firebaseConfig - The Firebase configuration object.
 * @param useEmulator - Whether to use the Firebase emulator.
 * @returns A Promise that resolves when Firebase is initialized.
 */
export async function initialiseFirebase(firebaseConfig: FirebaseConfig, useEmulator: boolean) {
	app = initializeApp(firebaseConfig);
	analytics = getAnalytics(app);
	// this because we get Sentry errors on some iOS devices
	// error - undefined is not an object (evaluating 'navigator.serviceWorker.addEventListener')
	// and possibly Messaging: This browser doesn't support the API's required to use the Firebase SDK. (messaging/unsupported-browser).
	// try {
	// 	if (await isSupported()) messaging = getMessaging(app);
	// } catch (e) {
	// 	console.error('Error getting messaging', e);
	// }

	auth = getAuth(app);

	// Sets to browserLocalPersistence
	// https://firebase.google.com/docs/auth/web/auth-state-persistence
	if (!dev) await setPersistence(auth, browserLocalPersistence);

	if (!useEmulator && dev) console.warn('NOT using emulator for Firebase');
	if (useEmulator) console.log('USING emulator for Firebase');

	if (useEmulator) {
		connectAuthEmulator(auth, 'http://localhost:9099', { disableWarnings: true });
		firestore = initializeFirestore(app, {}); //{ experimentalForceLongPolling: true }
		connectFirestoreEmulator(firestore, 'localhost', 8080);

		storage = getStorage(app);
		connectStorageEmulator(storage, 'localhost', 9199);
	} else {
		firestore = getFirestore(app);
		storage = getStorage(app);
	}

	getFCMToken = () => {
		// Sentry issue Messaging: This browser doesn't support the API's required to use the Firebase SDK. (messaging/unsupported-browser).
		// Let's see if this resolves it
		const token = Promise.resolve('');
		// return token;

		// try {
		// 	token = messaging !== undefined ? getTokenAPI(messaging, { vapidKey: firebaseConfig.vapidKey }) : Promise.resolve('');
		// } catch (e) {
		// 	console.error('Error getting FCM token', e);
		// }
		return token;
	};

	const channel = new BroadcastChannel('pn-messaging-channel');
	channel.onmessage = event => {
		// Handle the received message here
		console.log('Received message from service worker:', event.data);
		messages$.next({ source: 'background', payload: event });
	};

	// if (messaging !== undefined)
	// 	onMessage(messaging, payload => {
	// 		messages$.next({ source: 'foreground', payload });
	// 		console.log('Message received in foreground. ', JSON.stringify(payload.notification, null, 2));
	// 	});

	if (!dev && config.firebaseConfig.recaptchaV3publicKey) {
		appCheck = initializeAppCheck(app, {
			provider: new ReCaptchaV3Provider(config.firebaseConfig.recaptchaV3publicKey),
			isTokenAutoRefreshEnabled: true,
		});
	}
}

/**
 * The string value representing a granted permission.
 */
const grantedPermission = 'granted';

/**
 * Requests permission from the user to show notifications using the browser's built-in system dialog.
 * @returns A Promise that resolves to the user's permission status.
 */
export function startFCMRequestPermissionSystemDialog() {
	if (Notification && Notification.requestPermission) return Notification.requestPermission();
	else return Promise.reject();
}

/**
 * Requests permission for push notifications via system dialog.
 * @returns A Promise that resolves to a boolean indicating whether permission was granted or not.
 */
export async function requestPNPerdmissionViaSysDialog__REMOVE() {
	const permission: NotificationPermission = await startFCMRequestPermissionSystemDialog();
	return permission === grantedPermission;
}

/**
 * Gets the current notification permission status.
 * @returns The current notification permission status.
 */
export function getNotificationPermission() {
	if (Notification && Notification.permission) return Notification.permission;
	else return 'denied';
}

/**
 * A BehaviorSubject that emits the current user's authentication state and any subsequent changes.
 */
export const authStateChanged$ = new BehaviorSubject<User | null>(null);

/**
 * A BehaviorSubject that emits the latest value to new subscribers and stores the last emitted value.
 */
export const messages$ = new BehaviorSubject<any>(undefined);

/**
 * Returns HTTP options object with headers required for making authenticated REST API calls.
 * @returns {Promise<{ headers: { [key: string]: string } }>} HTTP options object with headers.
 */
export async function getHttpOptionsForREST() {
	const token = await auth.currentUser?.getIdToken();
	const httpOptions = {
		headers: {
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' + token,
		},
	};

	try {
		if (appCheck !== undefined) {
			const token = await getAppCheckToken(appCheck, false);
			(httpOptions.headers as { [key: string]: string })['X-Firebase-AppCheck'] = token.token;
		}
	} catch (e) {
		console.log('Error adding appcheck token', e);
	}

	return httpOptions;
}

/**
 * Creates an array of Firestore WriteBatch objects with the provided documents.
 * @param arrayOfDocs Array of documents to be batched
 * @param maxBatchSize Maximum size of each batch, default and maximum is 500
 */
export function createWriteBatches(arrayOfDocs: any[], maxBatchSize: number = MAX_BATCH_SIZE): WriteBatchData[] {
	// Array to store the created batches
	const batches: { batch: WriteBatch; batchData: any[] }[] = [];
	// Array to store documents for the current batch
	let currentBatchData: any[] = [];
	// Holds the current WriteBatch object
	let currentBatch: WriteBatch | null = null;

	// Ensure maxBatchSize does not exceed the predefined constant MAX_BATCH_SIZE
	maxBatchSize = maxBatchSize > MAX_BATCH_SIZE ? MAX_BATCH_SIZE : maxBatchSize;

	// Loop through each document in the array
	for (let i = 0; i < arrayOfDocs.length; i++) {
		if (currentBatchData.length === 0) {
			// If currentBatchData is empty, initialize a new WriteBatch
			currentBatch = writeBatch(firestore);
		}

		// Add the current document to the current batch's data array
		currentBatchData.push(arrayOfDocs[i]);

		// Check if the batch is full or if it's the last document in the array
		if (currentBatchData.length >= maxBatchSize || i === arrayOfDocs.length - 1) {
			if (currentBatch) {
				// Push the current batch and its data into the batches array
				batches.push({ batch: currentBatch, batchData: currentBatchData });
				// Reset currentBatchData for the next batch
				currentBatchData = [];
				// Reset currentBatch to null for the next batch
				currentBatch = null;
			}
		}
	}

	return batches;
}
