import { z } from 'zod';

export enum ContactUsEnum {
	whatsapp = 'default',
}

export const contactUsSchema = z.object({
	id: z.number().default(() => Math.floor(Date.now() + Math.random() * 1000)),
	gaEvent: z.string().default('contact_us'),
	title: z.string(),
	phone_number: z.string(),
	type: z.nativeEnum(ContactUsEnum).default(ContactUsEnum.whatsapp),
});

export type ContactUs = z.infer<typeof contactUsSchema>;
