import { z } from 'zod';
import { Farmer } from '../Farmers/farmer.schema';
import { Shop } from '../Shop/shop.schema';
import { Stats } from '../Stats/stats.schema';
import { User } from '../User/user.schema';

export enum BadgeID {
	default = 'default',
	first_farmer = 'first_farmer',
	fifth_farmer = 'fifth_farmer',
	tenth_farmer = 'tenth_farmer',
	second_login = 'second_login',
}

export interface Badge {
	id: BadgeID;
	icon: string;
	meta: string;
	rule: BadgeRule;
}

export interface BadgeRule {
	ruleFn: (data?: RuleData) => boolean;
}

export interface RuleData {
	farmers?: Farmer[];
	user?: User;
	stats?: Stats;
	shops?: Shop[];
}

export const awardedBadgeSchema = z.object({
	id: z.nativeEnum(BadgeID).default(BadgeID.default),
	awardedDate: z.number(),
});

export type AwardedBadge = z.infer<typeof awardedBadgeSchema>;
