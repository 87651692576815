import { BehaviorSubject } from 'rxjs';

// https://stackoverflow.com/questions/51503754/typescript-type-beforeinstallpromptevent
export interface BeforeInstallPromptEvent extends Event {
	readonly platforms: string[];
	readonly userChoice: Promise<{
		outcome: 'accepted' | 'dismissed';
		platform: string;
	}>;
	prompt(): Promise<void>;
}

declare global {
	interface WindowEventMap {
		beforeinstallprompt: BeforeInstallPromptEvent;
	}
}

export const beforeInstalledEventStore$ = new BehaviorSubject<BeforeInstallPromptEvent | undefined>(undefined);
