import { Clipboard } from '@capacitor/clipboard';
import { Share } from '@capacitor/share';
import { t } from 'i18next';
import { presentToast } from '../../Helpers/utils';
import { WaitingListEntry } from '../../Invitecodes/invite-code.schema';

export async function putCodeToClipAndShare(url: string) {
	writeToClipboard(url);
	presentToast('top', `Written link <b>${url}</b> to clipboard`);

	setTimeout(async () => {
		await Share.share({
			title: t('admin.titleshare') as string,
			text: t('admin.textshare') as string,
			url,
			dialogTitle: t('admin.dialogTitleshare') as string,
		});
	}, 500);
}

export async function writeToClipboard(message: string) {
	return await Clipboard.write({
		string: message,
	});
}

export function generateTxtDocument(dataArray: WaitingListEntry[] | any[], fileName: string): void {
	if (dataArray.length === 0) return;

	const textContent = dataArray
		.map(obj => {
			return (
				Object.values(obj)
					// .map(item => `'` + item + `'`) // yet to find the right text delimiter for excel
					.join(', ')
			);
		})
		.join('\n');

	const textHeaders = Object.keys(dataArray[0]).join(', ');

	const element = document.createElement('a');
	element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(textHeaders + '\n' + textContent));
	element.setAttribute('download', fileName);

	element.style.display = 'none';
	document.body.appendChild(element);

	element.click();

	document.body.removeChild(element);
}

export function generateUniqueCode() {
	const vowels = ['a', 'e', 'i', 'u'];
	let result = '';

	for (let i = 0; i < 6; i++) {
		if (i % 2 === 0) {
			// Even index, add a number
			const randomNumber = Math.floor(Math.random() * 10);
			result += randomNumber;
		} else {
			// Odd index, add a vowel
			const randomVowel = vowels[Math.floor(Math.random() * vowels.length)];
			result += randomVowel;
		}
	}

	return result;
}
