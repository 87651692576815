import { logOutAdminData } from '../Admin/admin-helper';
import { stopBadgeListning } from '../Badges/badges.store';
import { logOutCropStore } from '../Crop/crop.store';
import { logOutFarmerStore } from '../Farmers/farmer.store';
import { stopTokenRefresher, tokenRefresherIsActive } from '../Login/token-refresher';
import { logOutContactUsStore } from '../Promo/ContactUs/contactus.store';
import { logOutExploreMoreStore } from '../Promo/ExploreMore/exploremore.store';
import { logOutFAQStore } from '../Promo/FAQ/faq.store';
import { logOutPromotionalMessageStore } from '../Promo/PromotionalMessage/promotionalmessage.store';
import { logOutShopStore } from '../Shop/shop.store';
import { logOutStatsStore } from '../Stats/stats.store';
import { logOutTransactionStore } from '../Transaction/transaction.store';
import { logOutPermissionStore } from '../User/permission.store';
import { logOutUserStore } from '../User/user.store';

export async function logoutAllStores() {
	// Prevent token refresh after logout
	if (tokenRefresherIsActive()) {
		stopTokenRefresher();
	}

	await logOutAdminData();
	await logOutContactUsStore();
	await stopBadgeListning();
	await logOutTransactionStore();
	await logOutFarmerStore();
	await logOutShopStore();
	await logOutUserStore();
	await logOutPermissionStore();
	await logOutStatsStore();
	await logOutCropStore();
	await logOutFAQStore();
	await logOutExploreMoreStore();
	await logOutPromotionalMessageStore();
}
