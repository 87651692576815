export function getYearFromEpoch(epochTimestamp: number): string {
	// Create a Date object from the Unix epoch timestamp
	const date = new Date(epochTimestamp); // Multiply by 1000 to convert to milliseconds

	// Get the year from the Date object and convert it to a string
	const year = date.getFullYear().toString();

	return year;
}

export function getWeekNumberFromEpoch(unixTimestamp: number): number {
	// Convert the Unix timestamp to a Date object
	const date = new Date(unixTimestamp);

	// Get the first day of the year
	const start = new Date(date.getFullYear(), 0, 1);

	// Calculate the week number: round down the number of days since the start of the year divided by 7 (days in a week)
	return Math.floor(((date.valueOf() - start.valueOf()) / 86400000 + start.getDay() + 1) / 7);

	//	return week_number < 10 ? `0${week_number}` : `${week_number}`;
}

export function getDayFromEpoch(unixTimestamp: number): number {
	const date = new Date(unixTimestamp);
	return date.getDay(); // 0 = Sunday
}

export function getMonthFromEpoch(unixTimestamp: number): number {
	const date = new Date(unixTimestamp);
	return date.getMonth(); // 0 = Sunday
}

export function getYearMonthDayFromEpoch(unixTimestamp: number): string {
	const date = new Date(unixTimestamp);
	return `${date.getFullYear()}${formatNumberWithLeadingZero(date.getMonth() + 1)}${formatNumberWithLeadingZero(date.getDay() + 1)}`; // date.getMonth(); // 0 = Sunday
}

export function getYearWeekNrFromEpoch(unixTimestamp: number): string {
	const date = new Date(unixTimestamp);
	return `${date.getFullYear()}${formatNumberWithLeadingZero(getWeekNumberFromEpoch(unixTimestamp))}`;
}

export function formatNumberWithLeadingZero(num: number): string {
	if (num < 10) {
		// If the number is less than 10, add a leading '0' and convert to a string
		return `0${num}`;
	} else {
		// If the number is 10 or greater, just convert it to a string
		return num.toString();
	}
}

export function getYearMonthWeekEpoch(unixTimestamp: number) {
	const date = new Date(unixTimestamp);
	return `${date.getFullYear()}${formatNumberWithLeadingZero(getWeekNumberFromEpoch(unixTimestamp))}`; // date.getMonth(); // 0 = Sunday
}

export function categorizeNumberWithBuckets(buckets: number[], num: number): string {
	for (let i = 0; i < buckets.length; i++) {
		if (num <= buckets[i]) {
			return buckets[i].toString();
		}
	}
	return 'indefinite';
}

export function safeDivisionToString(a: any, b: any): string {
	if (a == undefined) return '-';
	if (b == undefined) return '-';

	if (Number.isNaN(a)) return '-';
	if (Number.isNaN(b)) return '-';

	if (!Number.isNaN(a) && !Number.isNaN(b) && b != 0) return Math.floor(a / b).toString();
	return 'n/a';
}

export function safeUndefinedNumber(value: any, key: string | number, fail: string): string {
	if (value == undefined) return fail;
	if (value[key] == undefined) return fail;
	return value[key];
}

export function millisecondsToDays(milliseconds: number): number {
	const millisecondsPerDay = 86400000; // 24 * 60 * 60 * 1000; // 24 hours * 60 minutes * 60 seconds * 1000 milliseconds
	return Math.floor(milliseconds / millisecondsPerDay);
}
