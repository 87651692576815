import packageJson from '../../package.json';
import * as Sentry from '@sentry/react';
import { createBrowserHistory } from 'history';

const history = createBrowserHistory();

export const sentryDefaultConfig = {
	release: `${packageJson.name} ${packageJson.version}`,
	integrations: [Sentry.reactRouterV5BrowserTracingIntegration({ history })],

	// We recommend adjusting this value in production, or using tracesSampler
	// for finer control
	tracesSampleRate: 1.0,

	// Capture Replay for 10% of all sessions,
	// plus for 100% of sessions with an error
	replaysSessionSampleRate: 0.1,
	replaysOnErrorSampleRate: 1.0,
};
