import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

import { registerSW } from 'virtual:pwa-register';
import { auth, initialiseFirebase } from './lib/Helpers/firebase/firebase';

import { BeforeInstallPromptEvent, beforeInstalledEventStore$ } from './lib/Install/install-helper';
import { updateFunction } from './lib/Install/update-helper';

import { t } from 'i18next';
import { config } from './assets/config';
import './index.css';
import { dev, presentToast } from './lib/Helpers/utils';
import LocaleContextProvider from './lib/stores/locale-context/locale-context';

import * as Sentry from '@sentry/react';
import { sentryDefaultConfig } from './constants/sentry-default-config';

// Setup Sentry (prod only)
if (!dev) {
	Sentry.init({
		...sentryDefaultConfig,
		...config.sentry,
	});
}

// Set up the service worker
const updateSW = registerSW({
	onNeedRefresh() {
		setTimeout(() => {
			// we don't kick the user already logged in
			if (auth === undefined) {
				presentToast('middle', t('general.pleasewait'));
				updateSW(true);
			} else if (auth && auth.currentUser === null) {
				presentToast('middle', t('general.pleasewait'));
				updateSW(true);
			}
			if (auth && auth.currentUser !== null) updateFunction.next(updateSW);
		}, 200);
	},
});

// Listen for the beforeinstallprompt event to support installation via app
window.addEventListener('beforeinstallprompt', (event: BeforeInstallPromptEvent) => {
	event.preventDefault();
	beforeInstalledEventStore$.next(event);
});

try {
	//@ts-ignore
	if (navigator.Notification == undefined) navigator.Notification = console.log;
	//@ts-ignore
	if (window.Notification == undefined) window.Notification = console.log;
} catch (e) {
	console.log('This browser does not support notifications.');
}

initialiseFirebase(config.firebaseConfig, dev);

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
	<React.StrictMode>
		<LocaleContextProvider>
			<App></App>
		</LocaleContextProvider>
	</React.StrictMode>
);
