import { getAuth, User } from 'firebase/auth';

let intervalRef: NodeJS.Timeout | undefined;
const refreshIntervalTime: number = 3540000; // 59 minutes

/**
 * Starts token refresher interval
 * @param intervalTime | Default is 3540000
 */
function startTokenRefresher(intervalTime = refreshIntervalTime): void {
	if (!intervalRef) {
		// Get user
		const user: User | null = getAuth().currentUser;
		if (user) {
			// Start interval
			intervalRef = setInterval(() => {
				refreshToken(user);
			}, intervalTime);
		}
	} else {
		throw new Error('Token refresher already active!');
	}
}

/**
 * Stop token refresher interval
 */
function stopTokenRefresher(): void {
	if (intervalRef) {
		clearInterval(intervalRef);
		intervalRef = undefined;
	} else {
		throw new Error('Token refresher is not active!');
	}
}

/**
 * Check if token refresher interval is active
 */
function tokenRefresherIsActive(): boolean {
	return !!intervalRef;
}

/**
 * Refresh firebase token
 */
function refreshToken(user: User): void {
	// Refresh token
	user.getIdToken(true).then();
}

export { refreshToken, startTokenRefresher, stopTokenRefresher, tokenRefresherIsActive };
